
<script>
  // Extensions
  import View from '@/views/View'

  // Mixins
  import LoadSections from '@/mixins/load-sections'

  export default {
    name: "Products",
  metaInfo: {
    title: "Products",
    titleTemplate: "%s | Tentifly - Smart sales | booking assistant"
  },
  extends: View,

    mixins: [
      LoadSections([
        //"hero-alt",
		    'products'
      ]),
    ],

    props: {
      id: {
        type: String,
        default: 'home',
      },
    },
  }
</script>
